import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { StoreView } from '/src/components/commerce'
import { SendEnquiryBlock } from '/src/components/common'
import { formatStores } from '/src/components/commerce/store/util'
import { removeTags, trimMetaDescription } from '/src/util'

export const Head = ({ data }) => {
  const [store] = data.allMcStoreMcStore.edges
  const hasBody = useMemo(() => store && store.node.field_mc_store_description ? true : false, [store.node.field_mc_store_description])

  return (
    <>
      <title>{store.node.name} | Metalcorp</title>
      {hasBody && <meta name="description" content={trimMetaDescription(removeTags(store.node.field_mc_store_description))} />}
    </>
  )
}

export default ({ data }) => {
  const [store] = data.allMcStoreMcStore.edges
  const feature = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: formatStores([store])
    }
  }, [store])

  return (
    <Layout>
      <Row className="mt-5">
        <Col className="mt-2" sm={6}>
          <div className="mb-5">
            <Link className="back-to-link" to='/store-locator'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to stores
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <div className="title-container mb-5">
            <h1 className="title">{store.node.name}</h1>
          </div>
          <StoreView {...store.node} feature={feature} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <SendEnquiryBlock />
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    allMcStoreMcStore(filter: {drupal_id: {eq: $id}}) {
      edges {
        node {
          name
          drupal_id
          field_mc_location {
            lat
            lon
          }
          dsap_plant_id
          field_mc_store_opening_times
          field_mc_store_email
          field_mc_store_description
          field_mc_store_delivery_estimate
          field_mc_store_address {
            address_line1
            address_line2
            country_code
            administrative_area
            locality
            postal_code
          }
          field_mc_store_phone_number
          field_mc_store_pickup_estimate
          path {
            alias
          }
        }
      }
    }
  }
`
